const form = document.querySelector('.jsForm');

const contactButton = document.querySelector('.jsContactButton');
const cancelButton = document.querySelector('.jsCancelButton');

const contactStatusEl = document.querySelector('.jsContactStatus');

const contactAnnounce = document.querySelector('.jsContactAnnounce');

const formCallback = e => {
  e.preventDefault();

  const postData = {
    name: form.name.value,
    mail: form.mail.value,
    content: form.content.value
  }

  contactStatusEl.classList.add('contact-area__status--loading');

  fetch( 'https://api.zaty.jp/contact/', 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(postData).toString()
    })
    .then( res => res.json() )
    .then( data => {
      contactStatusEl.classList.remove('contact-area__status--loading');
      if( data.result ) {
        form.name.value = '';
        form.mail.value = '';
        form.content.value = '';

        contactAnnounce.innerHTML = data.message;
      }else {
        contactAnnounce.innerHTML = data.message;
      }
    })

}

form.addEventListener('submit', formCallback);

contactButton.addEventListener('click', () => {
  form.classList.toggle('contact-area__field--open');
});
cancelButton.addEventListener('click', () => {
  form.classList.remove('contact-area__field--open');
});